
.set-box {
  border: 1px solid #E5E5E5;
}
.progress-box {
  background: #fafafa;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
  font-weight: 500;
  padding: 20px 74px;
  .progress-main-box {
    width: 100%;
    display: flex;
    .item-text {
      z-index: 1;
      width: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .small-sort {
      width: 100%;
      height: 32px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        background: #D5D5D5;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &.current {
        &:before {
          background: #FD4446;
        }
      }
    }
    .sort {
      width: 32px;
      height: 32px;
      border-radius: 16px;
      text-align: center;
      line-height: 32px;
      background: #D5D5D5;
      color: #fff;
      &.current {
        background: #FD4446;
      }
    }
    .active-text {
      color: #FD4446;
    }
    .line {
      //width: 1%;
      //flex: 1;
      width: calc(25% - 15px);
      height: 3px;
      background: #d9d9d9;
      margin-left: -30px;
      margin-right: -30px;
      margin-top: 14px;
      z-index: 0;
      &.current {
        background: #FD4446;
      }
    }
  }
}
.set-content {
  padding: 20px 74px 0;
}
