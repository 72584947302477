
.big-title {
  font-size: 16px;
  margin: 35px 0 26px;
}
.message {
  font-size: 12px;
  color: #999;
  line-height: 1.5;
  margin-top: 4px;
}
.title-form {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
}
.btn-box {
  text-align: right;
  padding: 10px 74px;
  border-top: 1px solid #DCDCDC;
  margin: 26px -74px 0 -74px;
}

:deep(.logo-uploader) {
  width: 120px;
  height: 120px;
  .el-upload {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F7F7F7;
  }
  .logo-cover {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
  .logo-uploader-icon {
    color: #999;
    font-size: 24px;
  }
  &.logo-uploader-mul {
    display: flex;
    width: 100%;
    .el-upload {
      &.el-upload--picture-card {
        width: 120px;
        height: 120px;
        border: none;
        background: #F7F7F7;
      }
    }
    .el-upload-list__item {
      width: 120px;
      height: 120px;
    }
  }
}
.blue {
  color: #2461EF;
}
.prompt-info {
  background: #FFFDF0;
  border: 1px solid #FFC8A0;
  padding: 12px 46px;
  line-height: 30px;
}
