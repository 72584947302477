
.prompt-text {
  font-size: 12px;
  color: #666;
}
.big-title {
  font-size: 16px;
  margin: 35px 0 26px;
}
.check-box {
  .el-checkbox {
    display: block;
    margin-right: 0;
    font-weight: 400;
    color: #333;
    margin-top: 16px;
    font-size: 12px;
    &:first-child {
      margin-top: 0;
    }
  }
}
.table-process {
  margin: 0 50px;
  border-right: 1px solid #E5E5E5;
  border-bottom: 1px solid #E5E5E5;
  .tr {
    width: 100%;
    display: flex;
    border-top: 1px solid #E5E5E5;
    .td {
      width: 20%;
      height: 50px;
      text-align: center;
      line-height: 50px;
      border-left: 1px solid #E5E5E5;
      position: relative;
      &:nth-child(odd) {
        background: #F8F8F8;
      }
      .head-icon {
        position: absolute;
        color: #E1E1E1;
        font-size: 40px;
        right: -20px;
        z-index: 1;
      }
      .body-icon {
        color: #FFAA00;
        font-size: 12px;
        margin-left: 4px;
      }
    }
  }
}
.ready-box {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin-left: -25px;
  margin-top: -10px;
  .item-box {
    margin-left: 75px;
    line-height: 1;
    margin-top: 10px;
    .item {
      background: #FD4446;
      padding: 26px 0 20px;
      color: #fff;
      width: 150px;
      .iconfont {
        font-size: 50px;
      }
      .text {
        font-size: 20px;
        margin-top: 20px;
      }
    }
    .name {
      font-size: 12px;
      color: #666;
      margin-top: 12px;
    }
  }
}
.btn-box {
  text-align: right;
  padding: 10px 74px;
  border-top: 1px solid #DCDCDC;
  margin: 26px -74px 0 -74px;
}
